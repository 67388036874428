@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,600;0,700;0,800;1,500&display=swap');

h1,
.h1 {
    font-size: $h1-font-size;
    font-weight: $font-weight-extrabold;
    line-height: $h1-line-height;
}

h2,
.h2 {
    font-size: $h2-font-size;
    font-weight: $font-weight-extrabold;
    line-height: $h2-line-height;
}

h3,
.h3 {
    font-size: $h3-font-size;
    font-weight: $font-weight-extrabold;
    line-height: $h3-line-height;
}

h4,
.h4 {
    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    line-height: $h4-line-height;
}

h5,
.h5 {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    line-height: $h5-line-height;
}

h6,
.h6 {
    font-size: $h6-font-size;
    font-weight: $font-weight-bold;
    line-height: $h6-line-height;
}

.display-title {
    font-size: $display-title-font-size;
    font-weight: $font-weight-bold;
    line-height: $display-title-line-height;

    @media screen and (max-width: 375px) {
        font-size: calc($display-title-font-size * .8);
        line-height: calc($display-title-line-height * .8);
    }
}

p,
.font-size-base {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

.font-size {
    &-sm {
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }

    &-md {
        font-size: $font-size-md;
        line-height: $line-height-md
    }
}

a {
    color: $link-color;
    text-decoration: none;
}

.label-1 {
    font-size: $label-1-font-size;
    font-weight: $label-1-font-weight;
    line-height: $label-1-line-height;
}

.label-2 {
    font-size: $label-2-font-size;
    font-weight: $label-2-font-weight;
    line-height: $label-2-line-height;
}

.label-3 {
    font-size: $label-3-font-size;
    font-weight: $label-3-font-weight;
    line-height: $label-3-line-height;
}

.label-4 {
    font-size: $label-4-font-size;
    font-weight: $label-4-font-weight;
    line-height: $label-4-line-height;
}

.img {
    position: relative;

    + .img-overlay {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Separator
.separator {
    position: relative;

    &:after {
        background: $bounds;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-vertical {
        &:after {
            height: 100%;
            margin: 0 .75rem;
            rotate: 180deg;
            width: 1px;
        }
    }
}
