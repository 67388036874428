//.btn
$btn-font-size: 0.9rem;
$btn-line-height: 1.2rem;
$btn-font-weidth: 700;
$btn-border-radius: 3rem;
$btn-border-radius-sm: 1.05rem;
$btn-border-radius-xs: 0.4rem;
$btn-padding-x: 0.9rem;
$btn-padding-y: 0.9rem;

//.btn-md
$btn-font-size-md: 0.85rem;
$btn-line-height-md: 1rem;
$btn-font-weight-md: 600;

//.btn-sm
$btn-font-size-sm: 0.75rem;
$btn-line-height-sm: 1rem;
$btn-font-weight-sm: 600;

//.btn-xs
$btn-font-size-xs: 0.6rem;
$btn-line-height-xs: 0.8rem;
$btn-font-weight-xs: 600;
$btn-padding-x-xs: 1rem;
$btn-padding-y-xs: 0.4rem;

// BUTTONS
//.btn-primary
$btn-primary-bg-color: $primary;
$btn-primary-bg-color-hover: $secondary;
$btn-primary-color: $white;

//:disabled
$btn-primary-bg-color-inactive: $secondary;
$btn-primary-border-color-inactive: $secondary;
$btn-primary-color-inactive: $white;

//.btn-outline-primary
$btn-outline-primary-border-color: $primary;
$btn-outline-primary-border-color-hover: $secondary;
$btn-outline-primary-border-color-inactive: $gray2; //.disabled
$btn-outline-primary-color: $primary;
$btn-outline-primary-hover-color: $secondary;
$btn-ouline-primary-inactive-color: $gray2; //.disabled

//.btn-mini
$btn-mini-bg-color: $bounds;
$btn-min-border-radius: 0.4rem;
$btn-mini-color: $dark1;
$btn-min-font-size: $label-3-font-size;
$btn-mini-padding-x: 0.6rem;
$btn-mini-padding-y: 0.4rem;

//:hover
$btn-mini-bg-color-hover: $primary;
$btn-mini-color-hover: $white;

//:disabled
$btn-mini-bg-color-inactive: $light1;
$btn-mini-color-inactive: $gray2;

//.btn-toolbar
$btn-toolbar-bg-color: $bounds;
$btn-toolbar-border-radius: 0.6rem;
$btn-toolbar-color: $gray1;
$btn-toolbar-font-size: 0.9rem;
$btn-toolbar-font-weight: 600;
$btn-toolbar-line-height: 1.3rem;
$btn-toolbar-padding-x: 0.65rem;
$btn-toolbar-padding-y: 0.55rem;

//:hover
$btn-toolbar-bg-color-hover: #a3cfd9;
$btn-toolbar-color-hover: $white;

//.active
$btn-toolbar-bg-color-active: $primary;
$btn-toolbar-color-active: $white;

//.btn-link
$btn-link-color: $primary;

//:hover
$btn-link-color-hover: $secondary;

//.active
$btn-link-color-active: $tertiary;

