.calendar-horizontal {
  background: $white;
  left: 0;
  margin: 0 auto;
  // max-width: 576px;
  right: 0;
  top: 3.4rem;
  width: 100%;
  overflow-y: hidden !important;

  .btn {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 1.9rem;
    justify-content: center;
    padding: auto;
    text-align: center;
    width: 1.9rem;
  }
}

.hide-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
