//.badge
$badge-padding-x: 0.7rem;
$badge-padding-y: 0.6rem;
$badge-font-size: 0.7rem;
$badge-line-height: 1rem;
$badge-font-weight: 600;
$badge-color: $dark1;
$badge-bg-color: $light2;

//.badge-primary
$badge-primary-bg-color: $bounds;
$badge-primary-color: $dark1;

    //:hover
    $badge-primary-bg-color-hover: $tertiary;
    $badge-primary-color-hover: $dark1;

    //.active
    $badge-primary-bg-color-active: $primary;
    $badge-primary-color-active: $white;

//.badge-social
    //:hover
    $badge-social-bg-color-hover: $social;
    $badge-social-color-hover: $dark1;

    //.active
    $badge-social-bg-color-active: $accent3;
    $badge-social-color-active: $dark1;

//.badge-sport
    //:hover
    $badge-sport-bg-color-hover: $tertiary;
    $badge-sport-color-hover: $dark1;

    //.active
    $badge-sport-bg-color-active: $primary;
    $badge-sport-color-active: $white;

//.badge-education
    //:hover
    $badge-education-bg-color-hover: $education;
    $badge-education-color-hover: $dark1;

    //.active
    $badge-education-bg-color-active: $accent4;
    $badge-education-color-active: $white;

//.badge-culture
    //:hover
    $badge-culture-bg-color-hover: $culture;
    $badge-culture-color-hover: $dark1;

    //.active
    $badge-culture-bg-color-active: $accent2;
    $badge-culture-color-active: $white;

//.badge-mini
$badge-mini-border-radius: 0.6rem;
$badge-mini-font-size: 0.6rem;
$badge-mini-font-weight: 700;
$badge-mini-line-height: 0.7rem;
$badge-mini-color: $dark1;
$badge-mini-bg-color: $white;
$badge-mini-padding-x: 0.3rem;
$badge-mini-padding-y: 0.15rem;