.cover-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cover-image-btn {
  background-color: rgba(0,0,0, 0.4);
  border-radius: 100px;
  color: white;
  // border-radius: 100px;
  // background: inherit;
  // filter: invert(1) grayscale(1) contrast(9);
}
