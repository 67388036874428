.navbar {
  // max-width: 576px;
  margin: 0 auto;
  padding: 0.5rem 1rem;

  &.fixed-top {
    background-color: $white;
    // backdrop-filter: blur($backdrop-blur);
  }

  &.fixed-bottom {
    // background-color: $white;
    backdrop-filter: blur($backdrop-blur);
    bottom: 0;
    left: 0;
    padding: 0 0.5rem;
    right: 0;

    // @media screen and (min-width: 510px) {
    //     left: calc((100% - 540px) / 2);
    //     right: calc((100% - 540px) / 2);
    // }
  }

  &-nav {
    justify-content: center;
  }
}
