.avatar {
    padding: 0!important;
    
    .sb-avatar {
        margin: 0!important;
        padding: 0!important;
    }

    &-list {
        &_item {
            height: 2.4rem;
            position: relative;
            width: 2.4rem;
                
            .avatar-container {
                box-shadow: 0 0 0 2px $white;
                position: absolute;
            }

            &:not(:first-of-type) {
                margin-right: -0.5rem;
            }
            
            &:nth-child(n + 1):not(:first-child) { 
                .avatar-container {
                    left: -0.5rem;
                }
            }
        }
    }

    &-container {
        position: relative;

        .avatar {
            box-shadow: none!important;
        }
    }

    &-raiting {
        bottom: -.5rem;
        position: absolute;
    }
}