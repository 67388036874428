@import "_variables";

.btn {
   border-radius: $btn-border-radius;
   box-shadow: none!important;
   font-size: $btn-font-size;
   font-weight: $btn-font-weidth;
   line-height: $btn-line-height;
   padding: $btn-padding-y $btn-padding-x;
}

.btn-primary {
   background: $btn-primary-bg-color;
   color: $btn-primary-color;
   display: block;

   &:hover {
      background: $btn-primary-bg-color-hover;
   }

   &.disabled,
   &:disabled {
      background: rgba($btn-primary-bg-color-inactive, 1);
      border-color: $btn-primary-border-color-inactive;
      color: $btn-primary-color-inactive;
      opacity: 1;
   }
}

.btn-outline-primary {
   background: transparent;
   box-shadow: 0 0 0 2px $btn-outline-primary-border-color;
   color: $btn-outline-primary-color;

   &:hover {
      box-shadow: 0 0 0 4px $btn-outline-primary-border-color-hover;
      color: $btn-outline-primary-hover-color;
   }

   &.disabled,
   &:disabled {
      box-shadow: 0 0 0 2px $btn-outline-primary-border-color-inactive;
      color: $btn-ouline-primary-inactive-color;
   }
}

.btn-mini {
   background: $btn-mini-bg-color;
   border-radius: $btn-min-border-radius;
   border: 0;
   color: $btn-mini-color;
   font-size: $btn-min-font-size;
   padding: $btn-mini-padding-y $btn-mini-padding-x;

   &:hover {
      background: $btn-mini-bg-color-hover;
      color: $btn-mini-color-hover;

      svg path {
         stroke: $white;
      }
   }

   &:disabled,
   &.disabled {
      background: $btn-mini-bg-color-inactive;
      color: $btn-mini-color-inactive;
   }
}

.btn-toolbar {
   .btn {
      background: $btn-toolbar-bg-color;
      border: 0;
      border-radius: $btn-toolbar-border-radius;
      color: $btn-toolbar-color;
      font-size: $btn-toolbar-font-size;
      font-weight: $btn-toolbar-font-weight;
      line-height: $btn-toolbar-line-height;
      min-width: 2.4rem;
      padding: $btn-toolbar-padding-y $btn-toolbar-padding-x;

      &:hover {
         background: $btn-toolbar-bg-color-hover;
         color: $btn-toolbar-color-hover;
      }

      &:active,
      &.active {
         background: $btn-toolbar-bg-color-active;
         color: $btn-toolbar-color-active;
      }

   }
}

.btn-search-toggle, .btn-search-toggle:hover, .btn-search-toggle:active, .btn-search-toggle:focus  {
  background-color: white !important;
  border: 2px solid $bounds !important;

  &.btn-active {
    background-color: $secondary !important;
  }
}

//.btn-link
.btn-link {
   color: $btn-link-color;

   &-base {
      text-decoration: none;

      &:hover {
         text-decoration: underline;
      }
   }

   &:hover {
      color: $btn-link-color-hover;
   }

   &:active,
   .active {
      color: $btn-link-color-active;
   }
}

// Btn sizes
.btn {
   &-md {
      font-size: $btn-font-size-md;
      font-weight: $btn-font-weight-md;
      line-height: $btn-line-height-md;
   }

   &-sm {
      font-size: $btn-font-size-sm;
      font-weight: $btn-font-weight-sm;
      line-height: $btn-line-height-sm;
   }

   &-xs {
      font-size: $btn-font-size-xs;
      font-weight: $btn-font-weight-xs;
      line-height: $btn-line-height-xs;
      padding: $btn-padding-y-xs $btn-padding-x-xs;
   }
}

.btn-outline-danger {
  --bs-btn-hover-color: white;
}
