// Default variable overrides
// COLORS
// Bootstrap's $theme-colors map
$primary: #49BCD6;
$secondary: #B3E4EE;
$tertiary: #38A8C0;
$accent2: #FF5B5B;
$accent3: #FFC000;
$accent4: #704FF2;
$culture: #F4BFBF;
$education: #C3B7F0;
$social: #FFE8A3;
$light1: #EEEFEF;
$light2: #F9F9F9;
$dark1: #031228;
$dark2: #161616;
$gray1: #646B6C;
$gray2: #929292;
$white: #ffffff;
$black: #000000;
$bounds: #E9F6F9;



$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $secondary,
    "warning": $culture,
    "danger": $accent2,
    "dark": $dark1,
    "light": $light1
);

// Project's colors map
$custom-theme-colors: (
    "accent1": $primary,
    "accent2": $accent2,
    "accent3": $accent3,
    "accent4": $accent4,
    "culture": $culture,
    "education": $education,
    "social": $social,
    "light2": $light2,
    "dark1":  $dark1,
    "dark2":  $dark2,
    "gray1": $gray1,
    "gray2": $gray2,
    "white": $white,
    "black": $black,
    "bounds": $bounds,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

//BASE
$body-bg: $white;
$body-color: #111;

// Typography
$font-size-root: 1.1rem; // 20px

// Fonts
$font-family-base: 'Twemoji Country Flags', 'Plus Jakarta Sans', sans-serif;
$font-size-base: .9rem;
$line-height-base: 1.3rem;

// GRID
$grid-columns:      12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//FORM
$form-check-input-width:                  1rem;
$form-check-padding-start:                $form-check-input-width + .5rem;
$form-check-margin-bottom:                .125rem;
$form-check-input-border-radius:          .25rem;
$form-check-inline-margin-end:            1rem;

$input-btn-padding-y:                     .8rem;
$input-btn-padding-x:                     .9rem;
$input-btn-font-family:                   $font-family-base;
$input-btn-font-size:                     $font-size-base;
$input-btn-line-height:                   $line-height-base;

$input-btn-focus-width:                   3px;
$input-btn-focus-color-opacity:           1;
$input-btn-focus-color:                   rgba($primary,1);
$input-btn-focus-blur:                    0;
$input-btn-focus-box-shadow:              0;

$input-btn-padding-y-sm:                  .25rem;
$input-btn-padding-x-sm:                  .5rem;
$input-btn-font-size-sm:                  $font-size-sm;

$input-btn-padding-y-lg:                  .8rem;
$input-btn-padding-x-lg:                  .9rem;
$input-btn-font-size-lg:                  $h3-font-size;

$input-btn-border-width:                  3px;

//$input-* are shared across most of our form controls (and not buttons).
$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-semibold;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;

$input-bg:                              $white;
$input-disabled-color:                  null;
$input-disabled-bg:                     $gray-200;
$input-disabled-border-color:           null;

$input-color:                           $gray2;
$input-border-color:                    $bounds;
$input-border-width:                    2px;
$input-box-shadow:                      null;

$input-border-radius:                   .6rem;
$input-border-radius-sm:                $border-radius-sm;
$input-border-radius-lg:                $border-radius-lg;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              tint-color($primary, 100%);
$input-focus-color:                     $dark2;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                null;

$input-placeholder-color:               $gray2;
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-color-width:                      3rem;

$backdrop-blur: 50px;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: $primary;
