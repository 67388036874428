@import "_variables";

.card {
    background: $card-bg;
    box-shadow: 0 0 0 $card-border-width $card-border-color;
    border-radius: $card-border-radius;
    border: 0;
    color: $card-color;
    font-size: $card-font-size;
    line-height: $card-line-height;
    margin: $card-margin-y $card-margin-x;
    padding: $card-padding-y $card-padding-x;
    position: relative;

    &:focus,
    &:hover {
        box-shadow: 0 0 0 $card-focused-border-width $card-focused-border-color;

        .card-body {
            // opacity: .6;
        }
    }

    &-body {
        align-items: center;
        display: flex;

        .title {
            font-weight: $card-title-font-weight;
        }
    }

    &.border-0 {
        border: 0;
        box-shadow: none;
    }

    &-crossroads {
        margin: .25rem;
        padding: $card-crossroads-padding-y $card-crossroads-padding-x;
    }

    &-list-group {
        padding: $card-list-group-padding;

        .list-group {
            &-item {
                border-bottom: $list-group-item-border-width solid $list-group-item-border-color;
                color: $list-group-item-color;
                font-size: $list-group-item-font-size;
                line-height: $list-group-item-line-height;
                padding: $list-group-item-padding-y $list-group-item-padding-x;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    &-list {
        margin: .35rem;

        &-item {
            margin: .35rem;
            width: calc(50% - .7rem);
        }

        .card-disabled {
            background-color: $light1;
            border-color: $light1!important;
            box-shadow: 0 0 0 2px $light1!important;
            color: $dark2;

            h3 {
                color: $dark2;
            }

        }
    }

    &-time {
        position: absolute;
        right: .6rem;
        top: .6rem;
    }
}
