@import "_variables";

.badge {
  background-color: $badge-bg-color;
    color: $badge-color;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: $badge-line-height;
    padding: $badge-padding-y $badge-padding-x;


  &-primary {
      background-color: $badge-primary-bg-color;
      color: $badge-primary-color;

    &.badge-btn {
        &:hover {
          background: $badge-primary-bg-color-hover;
          color: $badge-primary-color-hover;
        }

        &:active,
        &.active {
          background: $badge-primary-bg-color-active;
          color: $badge-primary-color-active;
        }
      }

        &.active {
          background: $badge-primary-bg-color-active;
            color: $badge-primary-color-active;
        }
                                                                                                                                }

                                                                &-social {
                                                                  &.badge-btn {
            &:hover {
              background: $badge-social-bg-color-hover;
                color: $badge-social-color-hover;
            }

            &:active,
            &.active {
              background: $badge-social-bg-color-active;
                color: $badge-social-color-active;
            }
                                                                                }

        &.active {
          background: $badge-social-bg-color-active;
            color: $badge-social-color-active;
        }
                                                                                                                                }

    &-sport {
      &.badge-btn {
          &:hover {
            background: $badge-sport-bg-color-hover;
            color: $badge-sport-color-hover;
          }

            &:active,
            &.active {
              background: $badge-sport-bg-color-active;
                color: $badge-sport-color-active;
            }
                                                                                                                                                                                                }

        &.active {
          background: $badge-sport-bg-color-active;
            color: $badge-sport-color-active;
        }
                                                                                                                                }

    &-education {
      &.badge-btn {
          &:hover {
            background: $badge-education-bg-color-hover;
            color: $badge-education-color-hover;
          }

            &:active,
            &.active {
              background: $badge-education-bg-color-active;
                color: $badge-education-color-active;
            }
                                                                                                                                                                                                }

        &.active {
          background: $badge-education-bg-color-active;
            color: $badge-education-color-active;
        }
                                                                                                                                }

    &-culture {
      &.badge-btn {
          &:hover {
            background: $badge-culture-bg-color-hover;
            color: $badge-culture-color-hover;
          }

            &:active,
            &.active {
              background: $badge-culture-bg-color-active;
                color: $badge-culture-color-active;
            }
                                                                                                                                                                                                }

        &.active {
          background: $badge-culture-bg-color-active;
            color: $badge-culture-color-active;
        }
                                                                                                                                }

    &-mini {
      background: $badge-mini-bg-color;
        border-radius: $badge-mini-border-radius;
        color: $badge-mini-color;
        font-size: $badge-mini-font-size;
        font-weight: $badge-mini-font-weight;
        line-height: $badge-mini-line-height;
        padding: $badge-mini-padding-y $badge-mini-padding-x;

    &-primary {
        background-color: $badge-primary-bg-color;
        color: $badge-primary-color;
      }
    }
}
.badge-count {
  line-height: 13px;
  font-size: 11px;
  text-align: center !important;
  min-width: 22px;
}

.badge-event-card {
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
}
