// Text color
// .text-[color]
.text {
    &-accent1 {
        color: $primary;
    }

    &-accent2 {
        color: $accent2;
    }

    &-accent3 {
        color: $accent3;
    }

    &-accent4 {
        color: $accent4;
    }

    &-culture {
        color: $culture;
    }

    &-education {
        color: $education;
    }

    &-social {
        color: $social;
    }

    &-light2 {
        color: $light2;
    }

    &-dark2 {
        color: $dark2;
    }

    &-gray1 {
        color: $gray1;
    }

    &-gray2 {
        color: $gray2;
    }

    &-white {
        color: $white;
    }

    &-black {
        color: $black;
    }

    &-bounds {
        color: $bounds;
    }
}

.text-transform {
    &-uppercase {
        text-transform: uppercase;
    }

    &-none {
        text-transform: none;
    }
}

// FONT WEIGHT
// extrabold: 800;
// bold: 700;
// semibold: 600;
.fw {
    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: 700;
    }

    &-800 {
        font-weight: 800;
    }
}
// MARGIN and PADDING
// custom-variables.scss

.mt {
    &-120 {
        margin-top: $spacer-120;
    }

    &-90 {
        margin-top: $spacer-90;
    }

    &-46 {
        margin-top: $spacer-46;
    }

    &-36 {
        margin-top: $spacer-36;
    }

    &-24 {
        margin-top: $spacer-24;
    }

    &-20 {
        margin-top: $spacer-20;
    }

    &-12 {
        margin-top: $spacer-12!important;
    }
}

.mb {
    &-90 {
        margin-bottom: $spacer-90;
    }

    &-80 {
         margin-bottom: $spacer-80;
    }

    &-46 {
        margin-bottom: $spacer-46;
    }

    &-36 {
        margin-bottom: $spacer-36;
    }

    &-24 {
        margin-bottom: $spacer-24;
    }

    &-20 {
        margin-bottom: $spacer-20;
    }

    &-12 {
        margin-bottom: $spacer-12;
    }
}

.ms {
    &-24 {
        margin-left: $spacer-24;
    }

    &-20 {
        margin-left: $spacer-20;
    }

    &-12 {
        margin-left: $spacer-12;
    }
}

.me {
    &-24 {
        margin-right: $spacer-24;
    }

    &-20 {
        margin-right: $spacer-20;
    }

    &-12 {
        margin-right: $spacer-12;
    }
}

.pt {
    &-90 {
        padding-top: $spacer-90;
    }

    &-80 {
        padding-top: $spacer-80;
    }

    &-70 {
        padding-top: $spacer-70;
    }

    &-60 {
        padding-top: $spacer-60;
    }

    &-46 {
        padding-top: $spacer-46;
    }

    &-36 {
        padding-top: $spacer-36;
    }

    &-24 {
        padding-top: $spacer-24;
    }

    &-20 {
        padding-top: $spacer-20;
    }

    &-12 {
        padding-top: $spacer-12;
    }
}

.pb {
    &-90 {
        padding-bottom: $spacer-90;
    }

    &-36 {
        padding-bottom: $spacer-36;
    }

    &-24 {
        padding-bottom: $spacer-24;
    }

    &-20 {
        padding-bottom: $spacer-20;
    }

    &-12 {
        padding-bottom: $spacer-12;
    }
}

.p {
    &-17 {
        padding: $spacer-17;
    }
}

//width and height
.vh-100 {
    height: 100vh;
}

.min-vh-100 {
    min-height: 100vh;
    height: auto;
}

.min-100 {
  min-height: 100%;
  height: auto;
}

.vw-100 {
    width: 100vw;
}

//images
.object-fit-cover {
    object-fit: cover;
}

.pre-line {
  white-space: pre-line;
  word-break: break-word;
}

