// .chat-message
$chat-message-font-size: .8rem;
$chat-message-font-weight: 500;
$chat-message-line-height: 1rem;
$chat-message-padding-x: .6rem;
$chat-message-padding-y: .3rem;
$chat-message-margin-y: .35rem;

    // --recived
    $chat-message-recived-bg-color: $white;
    $chat-message-recived-color: $dark1;
    $chat-message-recived-border-color: $light1;
    $chat-message-recived-border-radius: .5rem .5rem .5rem 0;

    // --sent
    $chat-message-sent-bg-color: $secondary;
    $chat-message-sent-color: $dark1;
    $chat-message-sent-border-color: $secondary;
    $chat-message-sent-border-radius: .5rem .5rem 0 .5rem;

// .chat-textarea
$chat-textarea-bg-color: $white;
$chat-textarea-border: 1px solid $light1;
$chat-textarea-border-radius: .8rem;
$chat-textarea-color: $dark2;
$chat-textarea-font-size: .7rem;
$chat-textarea-line-height: 1rem;
$chat-textarea-padding-x: .6rem;
$chat-textarea-padding-y: .4rem;