html {
  background: $white;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  height: auto;
  line-height: $line-height-base;
  // max-width: 576px;
  min-height: 100vh;
  margin: 0 auto;
}

//Welcome page
.hero {
  position: relative;
  background: url("/images/bg.svg") no-repeat;
  background-size: cover;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  max-height: 60vmax;
  min-height: 50vmax;

  .img {
    // max-height: 82vh;
    object-fit: cover;
    object-position: bottom;
    width: 100%;
    margin-top: -60px;
  }
  .img-overlay {
    padding: 1rem;
  }

  // .display-title {
  //   margin-top: 4.25rem;

  //   @media screen and (max-width: 374px), screen and (max-height: 811px) {
  //     margin-top: 1.5rem;
  //   }
  // }

  @media screen and (max-width: 374px), screen and (max-height: 811px) {
    height: calc(100vh - 165px);
  }
}

// Avatar image, registration / finish?_page.js
.avatar-image {
  display: inline-block;
  height: auto;
  position: relative;
  width: auto;

  .sport-image {
    left: calc(7rem / 2 - 2.6rem - 1.25rem);
    position: absolute;
    top: -0.6rem;
  }

  .social-image {
    position: absolute;
    right: -1.6rem;
    top: calc(7rem / 2 - 1.8rem);
  }

  .culture-image {
    bottom: -1.2rem;
    left: calc(7rem / 2 - 1.3rem);
    position: absolute;
  }
}

button.react-modal-sheet-backdrop {
  border: none;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-day {
  background-color: none !important;
}

.btn-day-active {
  color: white !important;
  &:active,
  &:hover {
    color: white !important;
  }
}

// header
.header {
  &.bg {
    background-repeat: no-repeat;
    background-size: cover;
    height: 6.6rem;
    position: relative;
    width: 100%;

    &-sport,
    &-primary {
      background-image: url("/images/bg-sport.png");
    }

    &-social {
      background-image: url("/images/bg-social.png");
    }

    &-education {
      background-image: url("/images/bg-education.png");
    }

    &-culture {
      background-image: url("/images/bg-culture.png");
    }
  }

  .header-images {
    bottom: -1.1rem;
    // left: .8rem;
    position: absolute;

    &--center {
      left: calc(50% - 2rem);
      bottom: -2rem;
    }

    &-content {
      left: 0;
      position: relative;
      text-align: center;
      top: 5.5rem;
      width: 100%;
    }
  }
}

// Profile detail page
.header-images {
  .avatar-image {
    bottom: 1rem;
    left: 0;
    position: relative;
  }

  .top-20 {
    position: relative;
    bottom: 1rem;
  }
}

.min-vh-100.pt-80 {
  min-height: calc(100vh - 80px);
}

.mb-55 {
  margin-bottom: 4rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --cc-text: #031228;
  --btns-bg: #38a8c0;
  --btns-hover-bg: #49bcd6;
  --btns-text-color: white;

  --cc-btn-primary-bg: var(--btns-bg) !important;
  --cc-btn-primary-hover-bg: var(--btns-hover-bg) !important;
  --cc-btn-secondary-bg: var(--btns-bg) !important;
  --cc-btn-secondary-hover-bg: var(--btns-hover-bg) !important;
  --cc-btn-secondary-text: var(--btns-text-color) !important;
  --cc-btn-secon-bg: var(--btns-bg) !important;
}

.b-bn .exp::before {
  border-color: unset !important;
}

.inactive-activity {
  color: grey !important;
  background-color: #f0f0f0;
}

.border-secondary-color {
  border-color: $secondary !important;
}
