//.card
$card-padding-x: 0;
$card-padding-y: 0;
$card-margin-x: 0;
$card-margin-y: .8rem;
$card-font-size: .9rem;
$card-line-height: 1.3rem;
$card-border-radius: .6rem;
$card-border-width: 2px;
$card-border-color: $bounds;
$card-bg: $white;
$card-color: $dark1;
$card-placeholder-color: $gray2;
$card-title-font-weight: 600;

//:focused
$card-focused-border-width: 3px;
$card-focused-border-color: $bounds;

//.list-group
$card-list-group-padding: 1rem;
$list-group-item-padding-x: 0;
$list-group-item-padding-y: .8rem;
$list-group-item-border-width: 2px;
$list-group-item-border-color: $bounds;
$list-group-item-font-size: .9rem;
$list-group-item-line-height: 1.3rem;
$list-group-item-color: $dark1;

//.card-crossroads
$card-crossroads-padding-x: .5rem;
$card-crossroads-padding-y: .7rem;
