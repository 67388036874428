// https://sweetalert2.github.io

.swal2 {
    &-popup {
        border-radius: .6rem!important;
    }

    // &-confirm {
    //     background-color: $primary!important;
    //     background-image: none!important;
    //     border: 0!important;
    //     color: $white!important;
    //     outline: none!important;
    // }

    // &-cancel {
    //     background-color: $accent2!important;
    //     background-image: none!important;
    //     border: 0!important;
    //     color: $white!important;
    // }

    // &-deny {
    //     background-color: $bounds!important;
    //     background-image: none!important;
    //     border: 0!important;
    //     color: $dark1!important;
    // }

    &-title {
        font-size: $h2-font-size!important;
        font-weight: $font-weight-bold!important;
        line-height: $h2-line-height!important;
        color: $dark1!important;
    }

    &-html-container {
        font-size: $font-size-base!important;
        font-weight: $font-weight-semibold!important;
        line-height: $line-height-base!important;
        color: $dark2!important;
    }

    &-error {
        border-color: $accent2!important;
        color: $accent2!important;

        [class^='swal2-x-mark-line'] {
            background-color: $accent2!important;
            color: $accent2!important;
        }
    }

    &-success {
        &-ring {
            border-color: $primary!important;
            color: $primary!important;
        }

        [class^='swal2-success-line'][class$='tip'] {
            background-color: $primary!important;
        }

        [class^='swal2-success-line'] {
            background-color: $primary!important;
        }
    }
}

.swal2-container {
  z-index: 9999999 !important;
}
