@import "_variables";

.chat {
    &-message {
        font-size: $chat-message-font-size;
        font-weight: $chat-message-font-weight;
        line-height: $chat-message-line-height;
        margin-bottom: $chat-message-margin-y;
        margin-top: $chat-message-margin-y;

        .message {
            padding: $chat-message-padding-y $chat-message-padding-x;
        }

        &--recived {
            color: $chat-message-recived-color;

            .message {
                background-color: $chat-message-recived-bg-color;
                border: 1px solid $chat-message-recived-border-color;
                border-radius: $chat-message-recived-border-radius;
            }
        }

        &--sent {
            color: $chat-message-sent-color;

            .message {
                background-color: $chat-message-sent-bg-color;
                border: 1px solid $chat-message-sent-border-color;
                border-radius: $chat-message-sent-border-radius;
            }
        }
    }

    &-textarea {
        position: relative;

        .form-control {
            background-color: $chat-textarea-bg-color;
            border: $chat-textarea-border;
            border-radius: $chat-textarea-border-radius;
            color: $chat-textarea-color;
            font-size: $chat-textarea-font-size;
            line-height: $chat-textarea-line-height;
            padding: $chat-textarea-padding-y 2rem $chat-textarea-padding-y $chat-textarea-padding-x;
        }

        .icon-send {
            position: absolute;
            bottom: -1.7rem;
            right: .3rem;
        }
    }
}

.chat-rooms-list {
  &_item {
    padding-left: 1rem;
  }

  .swipeable-list,
  .swipeable-list-item:not(.swipeable-list-item--remove) {
    overflow: inherit;
  }

  .swipeable-list-item__content {
    display: block !important;
    height: auto;
  }
}
