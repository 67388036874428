// TYPOGRAPHY
$h1-font-size: 1.5rem;
$h1-line-height: 2rem;

$h2-font-size: 1.1rem;
$h2-line-height: 1.6rem;

$h3-font-size: 1rem;
$h3-line-height: 1.6rem;

$h4-font-size: 1rem;
$h4-line-height: 1.6rem;

$h5-font-size: .8rem;
$h5-line-height: 1rem;

$h6-font-size: .7rem;
$h6-line-height: 1rem;

$display-title-font-size: 2.4rem;
$display-title-line-height: 3.5rem;

$link-color: $primary;

$label-1-font-size: .7rem;
$label-1-line-height: 1rem;
$label-1-font-weight: 800;

$label-2-font-size: .7rem;
$label-2-line-height: 1rem;
$label-2-font-weight: 600;

$label-3-font-size: .6rem;
$label-3-line-height: .8rem;
$label-3-font-weight: 600;

$label-4-font-size: .55rem;
$label-4-line-height: .75rem;
$label-4-font-weight: 700;

$font-size-sm: .75rem;
$line-height-sm: 1rem;

$font-size-md: .9rem;
$line-height-md: 1.2rem;

$font-weight-extrabold: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 500;

//FORMS
//.form-control
$form-control-padding-x: .9rem;
$form-control-padding-y: .8rem;
$form-control-font-size: $font-size-base;
$form-control-line-height: $line-height-base;
$form-control-border-radius: .6rem;
$form-control-border-width: 2px;
$form-control-border-color: $bounds;
$form-control-bg: $white;
$form-control-color: $dark2;
$form-control-placeholder-color: $gray2;

//:focused
$form-control-focused-color: $dark2;
$form-control-focused-border-width: 3px;
$form-control-focused-border-color: $primary;

//.is-valid
$form-control-valid-color: $bounds;
$form-control-valid-border-color: $dark2;
$form-control-valid-border-width: 2px;

//.is-invalid
$form-control-invalid-color: $accent2;
$form-control-invalid-border-color: $culture;
$form-control-invalid-border-width: 2px;

//.form-control-lg
$form-control-lg-font-size: $h3-font-size;
$form-control-lg-line-height: $h3-line-height;

// label
$form-label-font-size: .7rem;
$form-label-line-height: 1rem;
$form-label-font-weight: 700;
$form-label-text-color: $primary;
$form-label-padding-x: 1rem;
$form-label-padding-y: .4rem;

// MARGIN and PADDING
$spacer-120: 6rem;
$spacer-90: 4.5rem;
$spacer-80: 4rem;
$spacer-70: 3.5rem;
$spacer-60: 3rem;
$spacer-46: 2.3rem;
$spacer-36: 1.8rem;
$spacer-24: 1.2rem;
$spacer-20: 1rem;
$spacer-17: .85rem;
$spacer-12: .6rem;
