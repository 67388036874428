//.pincode-input-text
$pincode-padding-x: auto;
$pincode-padding-y: .5rem;
$pincode-font-size: $h1-font-size;
$pincode-line-height: $h1-line-height;
$pincode-border-radius: .6rem;
$pincode-border-width: 2px;
$pincode-border-color: $bounds;
$pincode-bg: $white;
$pincode-color: $dark2;
$pincode-placeholder-color: $gray2;
$pincode-width: 100%;
$pincode-margin: 0 .25rem;


//:focused
$pincode-focused-color: $dark2;
$pincode-focused-border-width: 3px;
$pincode-focused-border-color: $primary;

//.is-valid
$pincode-valid-color: $bounds;
$pincode-valid-border-color: $dark2;
$pincode-valid-border-width: 2px;

//.is-invalid
$pincode-invalid-color: $accent2;
$pincode-invalid-border-color: $culture;
$pincode-invalid-border-width: 2px;