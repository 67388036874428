.form-control {
    background: $form-control-bg;
    border: $form-control-border-width solid $form-control-border-color;
    border-radius: $form-control-border-radius;
    color: $form-control-color;
    font-size: $form-control-font-size;
    line-height: $form-control-line-height;
    padding: $form-control-padding-y $form-control-padding-x;

    &::placeholder {
        color: $form-control-placeholder-color;
    }

    &:focus,
    &:active {
        border: $form-control-focused-border-width solid $form-control-focused-border-color;
        color: $form-control-focused-color;
    }

    &.is-valid {
        border: $form-control-valid-border-width solid $form-control-valid-border-color;
        color: $form-control-valid-color;
    }

    &.is-invalid {
        border: $form-control-invalid-border-width solid $form-control-invalid-border-color;
        color: $form-control-invalid-color;
    }

    &-lg {
        font-size: $form-control-lg-font-size;
        line-height: $form-control-lg-line-height;
    }

    &-search {
        background: url('/images/search-ico.svg') no-repeat;
        background-position: .6rem center;
        padding-left: 2.4rem;
    }

    &-counter {
        .counter {
            bottom: .6rem;
            color: $gray2;
            font-size: .55rem;
            font-weight: 700;
            line-height: .75rem;
            position: absolute;
            right: .6rem
        }
    }

    &:-webkit-datetime-edit-day-field { background: green; }



    // ::-webkit-datetime-edit { padding: 1em; }
    // ::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; }
    // ::-webkit-datetime-edit-month-field { color: blue; }
    // ::-webkit-datetime-edit-day-field { color: green; }
    // ::-webkit-datetime-edit-year-field { color: purple; }
    // ::-webkit-inner-spin-button { display: none; }



    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background: $form-control-bg!important;
        border: $form-control-border-width solid $form-control-border-color!important;
        color: $form-control-focused-color!important;
    }

    /* Change text in autofill textbox */
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $dark1 !important;
        -webkit-background-clip: text!important;
    }
}

.form-switch {
    .form-check-input {
        background-color: #B3E4EE;
        background-image: url('/images/icons/knob.svg');
        background-position: 5px center;
        background-repeat: no-repeat;
        background-size: 1.2rem;
        border: 0;
        box-shadow: none;
        border-radius: 5rem;
        height: 1.6rem;
        width: 2.8rem;

        &:checked {
            background-color: $primary;
            background-image: url('/images/icons/knob.svg');
            background-position: 24px center;
            box-shadow: none;

            &:focus {
                background-image: url('/images/icons/knob-pressed.svg');
                background-position: 24px center;
                background-size: 20px;
            }
        }

        &:focus {
            background-image: url('/images/icons/knob-pressed.svg');
            background-position: 5px center;
            background-size: 20px;
            box-shadow: none;
        }
    }
}

.form-group {
    label {
        color: $form-label-text-color;
        font-size: $form-label-font-size;
        font-weight: $form-label-font-weight;
        line-height: $form-label-line-height;
        padding: $form-label-padding-y $form-label-padding-x;
        text-transform: uppercase;
    }
}

.pac-container {
  z-index: 9999999 !important;
}

.address-fulltext {
  z-index: 999;
  font-size: 16px;
  border: 1px solid $secondary;
  height: 44px;
  width: 70%;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
}
