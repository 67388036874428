@import "_variables";

.pincode {
    &-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &-input-text {
        background: $pincode-bg;
        border-width: $pincode-border-width!important;
        border-color: $pincode-border-color!important;
        border-radius: $pincode-border-radius;
        color: $pincode-color;
        font-size: $pincode-font-size;
        line-height: $pincode-line-height;
        margin: $pincode-margin!important;
        padding: $pincode-padding-y $pincode-padding-x;
        width: $pincode-width!important;

        &:first-of-type {
            margin-left: 0!important;
        }

        &:last-of-type {
            margin-right: 0!important;
        }

        &::placeholder {
            color: $pincode-placeholder-color;
        }

        &:focus,
        &:active {
            border: $pincode-focused-border-width solid $pincode-focused-border-color!important;
            color: $pincode-focused-color;
        }

        &.is-valid {
            border: $pincode-valid-border-width solid $pincode-valid-border-color!important;
            color: $pincode-valid-color;
        }

        &.is-invalid {
            border: $pincode-invalid-border-width solid $pincode-invalid-border-color!important;
            color: $pincode-invalid-color;
        }
    }
}